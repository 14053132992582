import * as React from "react"
import {Link,} from "gatsby"

import iconPdf from "/src/images/icon-pdf.png"
import iconExcel from "/src/images/icon-excel.png"
import iconWord from "/src/images/icon-word.png"
import iconPpt from "/src/images/icon-ppt.png"

const Assets = ({children,data,name,nameen}) => {

  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja'   
  const file = data.assets.edges.filter(({node}) =>{
    return node.base === (lang==='ja'?name:(nameen?nameen:name))
  })

  if(file.length===1){
    
    const href = '/assets/'+file[0].node.base
    
    let sizetext=''
    let icon = '';

    if(file[0].node.extension === "pdf"){
      sizetext='(PDF '+ file[0].node.prettySize +')'
      icon = iconPdf
    }else if(file[0].node.extension === "xlsx"){
      sizetext='(PDF '+ file[0].node.prettySize +')'
      icon = iconExcel
    }else if(file[0].node.extension === "pptx"){
      sizetext='(PDF '+ file[0].node.prettySize +')'
      icon = iconPpt
    }else if(file[0].node.extension === "docx"){
      sizetext='(PDF '+ file[0].node.prettySize +')'
      icon = iconWord
    }

    return (
      <>
        <Link to={href}>
          {children}
          {sizetext}
          <img className="icon" src={ icon } alt="" />
        </Link>
      </>
    )
  }else{
    return (
      <></>
    )
  }

  
}

export default Assets
