import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import accessmapen from "/src/images/access-map-en.jpg"
import accessmap from "/src/images/access-map.png"

const Page = ({data}) => {

  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const img = getImage(lang);

  return  (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("アクセス")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("アクセス")}</h1>
        {img}
        <h2>{t("交通案内")}</h2>
        <p>{t("access text")}</p>
        <h3 className="headline3-icon jr">
          {t("JR常磐線（牛久駅下車）")}
        </h3>
        <p>
          {t("牛久駅より関東鉄道バス利用 約20分")}<br />
          {t("牛久駅西口バスターミナルより「谷田部車庫」行き、「高野台中央」下車 徒歩1分")}
        </p>
        <h3 className="headline3-icon tx">
          {t("つくばエクスプレス（つくば駅下車）")}
        </h3>
        <p>
          {t("つくば駅よりつくバス南部シャトル「茎崎窓口センター」または「茎崎老人福祉センター」行き、「理化学研究所」下車 徒歩1分")}
        </p>
        
        <h3 className="headline3-icon car">
          {t("自動車")}
        </h3>
        <p>
          {t("常磐自動車道 谷田部ICより約6km、つくば牛久ICより約3km")}
        </p>

        <h3 className="headline3-icon taxi">
          {t("タクシー")}
        </h3>
        <p>
          {t("JR牛久駅タクシー乗り場から 所要時間約15分")}<br />
          {t("TXつくば駅タクシー乗り場から 所要時間約20分")}
        </p>

      </ContentLayout>
    </Layout>
  )
  }
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`

function getImage(language) {
  if(language==='en'){
    return (
      <img src={ accessmapen } alt="Map showing traffic information on RIKEN Tsukuba Campus"  className="img-wide"/>
    )
  }else{
    return (
        <img src={ accessmap } alt="理化学研究所筑波キャンパスの交通案内を示した地図"  className="img-wide"/>
    )
  }
}

