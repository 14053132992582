import * as React from "react"


const DataListRow = ({children,title}) => {
  
  return (
    <>
      <tr>
        <th>{title}</th>
        <td>{children}</td>
      </tr>
    </>
  )
}

export default DataListRow
