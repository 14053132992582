import * as React from "react"
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import iconBlank from "/src/images/icon-blank-02.png"

const External = ({children,href,btn}) => {

    const {t} = useTranslation()
    
    let cls = ''
    if(btn==="1"){
      cls="btn btn01 noicon"
    }else if(btn==="2"){
      cls="btn btn02 noicon"
    }else if(btn==="3"){
    }

    return (
      <>
          <a className={cls}
              href={href}
              rel="noreferrer noopener"
              target="_blank">

            {children}

            <img className="icon" src={ iconBlank } alt={t("新規タブで開きます")} />
          </a>
      </>
    )
  

  
}

export default External

