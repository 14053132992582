import * as React from "react"

const DataList = ({children}) => {
  return (
    <>
    <table className="datalist">
      <tbody>
        {children}
      </tbody>
    </table>
    </>
  )
}

export default DataList