import * as React from "react"
import {Link} from "gatsby"
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import iconPdf from "/src/images/icon-pdf.png"
import iconExcel from "/src/images/icon-excel.png"
import iconWord from "/src/images/icon-word.png"
import iconPpt from "/src/images/icon-ppt.png"

const Assets = ({children,href}) => {

  const {t} = useTranslation()
  const extension = href.split('.').pop()
  
  let icon = ''


  if(extension === "pdf"){
    icon = iconPdf
  }else if(extension === "xlsx"){
    icon = iconExcel
  }else if(extension === "pptx"){
    icon = iconPpt
  }else if(extension === "docx"){
    icon = iconWord
  }

  if(href.indexOf('://')!==-1){
    return(
      <>
        <a href={href}
              rel="noreferrer noopener"
              target="_blank">

              {children}

            <img className="icon" src={ icon } alt={t("新規タブで開きます")} />
          </a>
      </>
    )
  }else{
    return(
    <>
      <Link to={href}>
        {children}
        <img className="icon" src={ icon } alt="" />
      </Link>
    </>
    )
  }
}

export default Assets
